import { template as template_2a2d45ab42a840fbb67a91690fed5b92 } from "@ember/template-compiler";
const WelcomeHeader = template_2a2d45ab42a840fbb67a91690fed5b92(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
